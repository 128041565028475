import './external-user-profile.styles.scss';

import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types/root-state';
import { setAlert } from '../../redux/General/general.actions';
import { IStageDataItem } from '../../types/interfaces/stage.interface';
import {
	clearProfileData,
	getAmbassadorCheckStatus,
	getChecklist,
	getLicenses,
	selectApplicant,
	setAddressHistory,
	setCriminalBankruptcy,
	setNameHistory,
	setOverallSubmission,
	setStageData,
} from '../../redux/Profile/profile.actions';
import {
	getApplicantStatusBackgroundColour,
	getStatusClassName,
	mapSubmissionStatusTab,
} from '../../utils/externalUtilsFunction';

import * as apiApplicant from '../../api/api.applicant';
import * as apiStage from '../../api/api.stage';
import * as utils from '../../utils/utilsFunctions';

import useGetWorkHistory from '../../utils/useGetWorkHistory.component';
import ExternalUserProfileTabs from '../../components/external-user-profile/external-user-profile-tabs/external-user-profile-tabs.component';
import ExternalProfileSidebar from '../../components/external-user-profile/external-profile-sidebar/external-profile-sidebar.component';
import ErrorBoundary from '../../components/general/error-boundary/error-boundary.component';
import { IApplicant } from '../../types/interface';

const ExternalUserProfile: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { state }: any = useLocation();

	const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);

	const role = useSelector((state: RootState) => state.auth.role);

	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const [uuid, setUuid] = useState('');

	useGetWorkHistory(applicant.workerUuid);

	const getApplicantStatus = (applicant: IApplicant) => {
		return (
			<div
				className='external-user-profile-header-status'
				style={{
					backgroundColor: `${getApplicantStatusBackgroundColour(
						applicant.submissionStatusTab,
					)}`,
				}}
			>
				<span
					className={getStatusClassName(
						applicant.submissionStatusTab,
					)}
				>
					{mapSubmissionStatusTab(applicant.submissionStatusTab)}
				</span>
			</div>
		);
	};

	useEffect(() => {
		if (state && state.uuid) {
			setUuid(state.uuid);
		} else if (!state && isLoggedIn && window.location.pathname) {
			const uuid = window.location.pathname.replace(
				/\/check\/user\//,
				'',
			);
			setUuid(uuid);
		} else {
			navigate('/login', { replace: true });
			return;
		}
	}, []);

	useEffect(() => {
		if (uuid !== applicant.uuid) dispatch(clearProfileData());
	}, [uuid]);

	useEffect(() => {
		if (uuid) {
			apiApplicant
				.getApplicant(uuid)
				.then((res) => {
					dispatch(selectApplicant(res.data));
				})
				.catch(() => {
					dispatch(
						setAlert({
							type: 'error',
							message: 'Unable to get applicant',
							isVisible: true,
						}),
					);
				});
		}
	}, [dispatch, isLoggedIn, role, uuid]);

	useEffect(() => {
		if (
			applicant &&
			applicant.workerUuid &&
			applicant.uuid &&
			applicant.uuid === uuid
		) {
			const getApplicantData = async () => {
				const [
					overallSubmissionRes,
					checklistRes,
					licencesRes,
					nameHistoryRes,
					addressHistoryRes,
					criminalRecordRes,
					ambassadorCheckStatus,
					stageData,
				] = await Promise.all([
					apiApplicant
						.getOverallSubmission(applicant.uuid)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'checklist',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'licences',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'name-history',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'address-history',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'worker-declaration',
						)
						.catch((err) => {
							throw err;
						}),
					apiApplicant
						.getApplicantDataByWorkerUuid(
							applicant.workerUuid,
							'face-to-face',
						)
						.catch((err) => {
							throw err;
						}),
					apiStage.getStageData(applicant.workerUuid).catch((err) => {
						throw err;
					}),
				]);

				const actionList = [
					setOverallSubmission(overallSubmissionRes),
					getChecklist(checklistRes),
					getLicenses(licencesRes),
					setNameHistory(
						utils.sortByDate(
							nameHistoryRes.status !== 404 ? nameHistoryRes : [],
						),
					),
					setAddressHistory(
						utils.sortByDate(
							addressHistoryRes.status !== 404
								? addressHistoryRes
								: [],
						),
					),
					setCriminalBankruptcy(criminalRecordRes),
					getAmbassadorCheckStatus(ambassadorCheckStatus),
					setStageData(
						stageData.data.map((stage: IStageDataItem) => {
							if (stage.stageComplete === null) {
								stage.stageComplete = false;
							}
							return stage;
						}),
					),
				];

				actionList.forEach((action) => {
					dispatch(action);
				});
			};
			getApplicantData();
		}
	}, [applicant.uuid, applicant.workerUuid, dispatch, role]);

	return (
		<>
			<div className='external-user-profile-container'>
				<div className='external-user-profile-header-container'>
					<div className='external-user-profile-header'>
						{applicant.name + ' ' + applicant.surname}
					</div>
					{getApplicantStatus(applicant)}
				</div>
				<ErrorBoundary
					applicantWorkerUuid={
						applicant ? applicant.workerUuid : 'None'
					}
				>
					<ExternalUserProfileTabs />
				</ErrorBoundary>
			</div>
			<ErrorBoundary
				applicantWorkerUuid={applicant ? applicant.workerUuid : 'None'}
			>
				<ExternalProfileSidebar />
			</ErrorBoundary>
		</>
	);
};

export default ExternalUserProfile;
