import './external-user-profile-sections.styles.scss';

import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import {
	capitalizeAndFormatPostcode,
	formatDateToText,
} from '../../../utils/externalUtilsFunction';
import { IFileItem } from '../../../types/interfaces/document.interface';

import * as utils from '../../../utils/utilsFunctions';
import ExternalUserProfileStatus from './external-user-profile-status.component';

import filesIcon from '../../../assets/icons/external-portal/user-profile/files.svg';
import houseIcon from '../../../assets/icons/external-portal/user-profile/house.svg';
import downloadFileIcon from '../../../assets/icons/external-portal/user-profile/download-file.svg';

interface Props {
	addressFileArray: IFileItem[];
}

const ExternalUserProfileAddress: FC<Props> = ({ addressFileArray }) => {
	const addressHistory = useSelector(
		(state: RootState) => state.profile.addressHistory,
	);

	const addressStageData = useSelector(
		(state: RootState) => state.profile.stageData[3],
	);

	const visibleFiles = addressFileArray.filter(
		(file: IFileItem) => !file.deletedAt && file.status !== 'rejected',
	);

	const renderAddressHistorySubSections = () => {
		if (!addressHistory) {
			return null;
		}

		return addressHistory
			.filter((history) => history.type !== 'gap')
			.map((history, index) => (
				<section
					key={index}
					className='external-user-profile-sub-section-container'
				>
					<div className='external-user-profile-sub-section-title-container remove-pointer'>
						<img
							className='external-user-profile-sub-section-icon'
							src={houseIcon}
							alt='house-icon'
						></img>
						<p className='external-user-profile-sub-section-title'>
							{history.address.postcode &&
								capitalizeAndFormatPostcode(
									history.address.postcode,
								)}
						</p>
						<p className='external-user-profile-sub-section-dates'>
							{utils.formatDateForUser(history.startAt)} -{' '}
							{history.endAt
								? new Date(history.endAt).toDateString() ===
									new Date().toDateString()
									? 'Present'
									: utils.formatDateForUser(history.endAt)
								: 'Present'}
						</p>
					</div>
					<div className='external-user-profile-sub-section-subtitle-container'>
						<p className='external-user-profile-sub-section-subtitle'>
							{utils.capitalizeFirstLetterOfEach(
								history.address.streetAddress,
							)}
							,{' '}
							{utils.capitalizeFirstLetterOfEach(
								history.address.city,
							)}
							,{' '}
							{history.address.postcode &&
								capitalizeAndFormatPostcode(
									history.address.postcode,
								)}
						</p>
					</div>
					<div className='external-user-profile-sub-section-divider-line'></div>
				</section>
			));
	};

	const renderAddressHistoryFileSection = () => {
		return (
			<section className='external-user-profile-sub-section-container'>
				<div className='external-user-profile-sub-section-title-container remove-pointer'>
					<img
						className='external-user-profile-sub-section-icon'
						src={filesIcon}
						alt='files-icon'
					></img>
					<p className='external-user-profile-sub-section-title external-user-profile-sub-section-title-margin'>
						Files
					</p>
				</div>
				{visibleFiles && visibleFiles.length > 0 ? (
					<>
						{visibleFiles.map((file: IFileItem, index: number) => (
							<div
								key={index}
								className='user-profile-section-file-container'
							>
								<div className='user-profile-section-file-content'>
									<a
										href={file.readUrl}
										target='_blank'
										rel='noreferrer'
										className='user-profile-section-file-link'
									>
										<img
											className='user-profile-section-file-icon'
											src={downloadFileIcon}
											alt='file-icon'
										/>
										<span className='user-profile-section-file-number'>
											File{' '}
											{visibleFiles.indexOf(file) + 1}
										</span>
									</a>
									<span
										className='user-profile-section-file-status'
										style={{
											color:
												file.status === 'accepted'
													? '#485BEB'
													: file.status ===
														  'unselected'
														? '#F8C96A'
														: '#EC8079',
										}}
									>
										{file.status === 'unselected'
											? 'Pending'
											: utils.capitalizeFirstLetter(
													file.status,
												)}
									</span>
								</div>
							</div>
						))}
					</>
				) : (
					<div className='external-user-profile-sub-section-subtitle-container'>
						<p className='external-user-profile-sub-section-subtitle'>
							No Files Available
						</p>
					</div>
				)}
			</section>
		);
	};

	return (
		<section className='view-container'>
			<ExternalUserProfileStatus
				sectionStatus={
					addressStageData?.stageComplete ? 'verified' : ''
				}
			/>
			<p className='user-profile-section-title'>Address History</p>
			<div className='user-profile-section-divider-line'></div>
			<div className='user-profile-section-main'>
				{renderAddressHistorySubSections()}
				{renderAddressHistoryFileSection()}
			</div>
			{addressStageData?.stageComplete && (
				<div className='last-verified'>
					Last Verified on{' '}
					{formatDateToText(addressStageData.stageCompletedAt)}
				</div>
			)}
		</section>
	);
};

export default ExternalUserProfileAddress;
