import './external-profile-sidebar-section.styles.scss';

import React, { FC, useState } from 'react';
import { UserProfileInfoToolTip } from '../../../utils/externalUtilsFunction';

import greyInfoIcon from '../../../assets/icons/grey-info.svg';
// import greenInfoIcon from '../../../assets/icons/green-info.svg';

interface SectionTitleProps {
	header: string;
	text: string | React.ReactNode;
	icon?: string;
	iconShown?: boolean;
	tooltipMessage?: string;
	collapsibleStyle?: { [key: string]: string };
}

const ExternalProfileSidebarSection: FC<SectionTitleProps> = ({
	header,
	text,
	icon,
	iconShown,
	tooltipMessage = '',
	collapsibleStyle,
}) => {
	const [isTooltip, setIsTooltip] = useState(false);

	const excludedHeaders = [
		'Clearance Status',
		'Phone Number',
		'Date of Birth',
		'Email',
		'Date of Fully Cleared',
		'Date of Limited Screening',
	];

	const handleTooltip = (value: boolean) => {
		setIsTooltip(value);
	};

	let clearanceStatusTextColour;

	switch (text) {
		case 'Cancelled':
			clearanceStatusTextColour = { color: '#EC8079' };
			break;
		case 'Limited Screening':
			clearanceStatusTextColour = { color: '#DDA639' };
			break;
		case 'Full':
			clearanceStatusTextColour = { color: '#09AD99' };
			break;
		default:
			clearanceStatusTextColour = {};
	}

	return (
		<div className='external-profile-sidebar-section-title-container'>
			<div className='external-profile-sidebar-section-header'>
				<h4
					className='external-profile-sidebar-section-title'
					style={collapsibleStyle}
				>
					{header}
				</h4>
				{iconShown && (
					<div
						onMouseEnter={() => handleTooltip(true)}
						onMouseLeave={() => handleTooltip(false)}
					>
						<img
							className='grey-info-icon'
							src={greyInfoIcon}
							alt='info icon'
						/>
					</div>
				)}
			</div>
			<UserProfileInfoToolTip
				title={tooltipMessage}
				placement='bottom-start'
				open={isTooltip}
				PopperProps={{
					modifiers: [
						{
							name: 'offset',
							options: {
								offset: [0, 10],
							},
						},
					],
				}}
			>
				<span />
			</UserProfileInfoToolTip>
			<div className='external-profile-sidebar-section-body'>
				{header === 'Clearance Status' && (
					<div className='external-profile-sidebar-section-clearance-container'>
						<img
							src={icon}
							alt='clearance-status-icon'
							className='external-profile-sidebar-section-clearance-icon'
						/>
						<div
							className='clearance-status-text'
							style={clearanceStatusTextColour}
						>
							{text}
						</div>
					</div>
				)}
				{(header === 'Date of Fully Cleared' ||
					header === 'Date of Limited Screening') && (
					<div className='clearance-date-margin'>
						<div>{text}</div>
					</div>
				)}
				{(header === 'Phone Number' ||
					header === 'Date of Birth' ||
					header === 'Email') && (
					<div className='no-icon-margin' style={collapsibleStyle}>
						<div>{text}</div>
					</div>
				)}
				{!excludedHeaders.includes(header) && <div>{text}</div>}
			</div>
		</div>
	);
};

export default ExternalProfileSidebarSection;
