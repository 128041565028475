import './external-user-profile-sections.styles.scss';

import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { User } from '../../../types/interface';

import * as utils from '../../../utils/utilsFunctions';
import ExternalUserProfileStatus from './external-user-profile-status.component';
import ExternalUserProfileRisks from '../external-user-profile-risks/external-user-profile-risks.component';

import flagDarkGrey from '../../../assets/icons/flag-dark-grey.svg';
import flagRed from '../../../assets/icons/flag-red.svg';
import securityGuardingIcon from '../../../assets/icons/external-portal/user-profile/security-guarding.svg';
import doorSupervisionIcon from '../../../assets/icons/external-portal/user-profile/door-supervision.svg';
import closeProtectionIcon from '../../../assets/icons/external-portal/user-profile/close-protection.svg';
import keyIcon from '../../../assets/icons/external-portal/user-profile/key.svg';
import cctvIcon from '../../../assets/icons/external-portal/user-profile/cctv.svg';
import transitIcon from '../../../assets/icons/external-portal/user-profile/transit.svg';
import vehicleIcon from '../../../assets/icons/external-portal/user-profile/vehicle-immobilisation.svg';
import nonFrontLineIcon from '../../../assets/icons/external-portal/user-profile/non-front-line.svg';

type Props = {
	loggedInUser: User;
};

const ExternalUserProfileQualifications: FC<Props> = ({ loggedInUser }) => {
	const licences = useSelector((state: RootState) => state.profile.licences);

	const licencesStageData = useSelector(
		(state: RootState) => state.profile.stageData[2],
	);

	const [selectedTabs, setSelectedTabs] = useState<{
		[key: string]: 'front' | 'back';
	}>({});

	useEffect(() => {
		if (licences) {
			setSelectedTabs(
				licences.reduce(
					(acc, licence) => ({ ...acc, [licence.number]: 'front' }),
					{},
				),
			);
		}
	}, [licences]);

	const getQualificationIcon = (type: string) => {
		switch (type) {
			case 'Security Guarding':
				return securityGuardingIcon;
			case 'Door Supervision SIA License':
			case 'Door Supervision':
				return doorSupervisionIcon;
			case 'Close Protection':
			case 'Close Protection SIA License':
				return closeProtectionIcon;
			case 'Key Holding':
			case 'Key Holding SIA License':
				return keyIcon;
			case 'CCTV SIA License':
			case 'Public Space Surveillance (CCTV)':
				return cctvIcon;
			case 'Security Guarding SIA License':
				return securityGuardingIcon;
			case 'Cash & Valuables Transit SIA License':
				return transitIcon;
			case 'Vehicle Immobilisation':
				return vehicleIcon;
			case 'Non Front Line SIA License':
				return nonFrontLineIcon;
			default:
				return nonFrontLineIcon;
		}
	};

	const renderQualificationsSection = () => {
		return licences.map((licence, index) => (
			<React.Fragment key={licence.number}>
				<div className='user-profile-section-divider-line'></div>
				<section
					key={index}
					className='external-user-profile-sub-section-licence-container'
				>
					<div className='external-user-profile-sub-section-licence-details-container'>
						<div className='external-user-profile-sub-section-licence-title-container remove-pointer'>
							<img
								className='external-user-profile-sub-section-licence-icon'
								src={getQualificationIcon(licence.sector)}
								alt='licence-sector-icon'
							></img>
							<p className='external-user-profile-sub-section-licence-title'>
								{licence.number}
							</p>
						</div>
						<div className='external-user-profile-sub-section-licence-subtitle-container'>
							<p className='external-user-profile-sub-section-licence-subtitle'>
								{licence.sector} - {licence.role}
							</p>
						</div>
						<div className='external-user-profile-sub-section-licence-body'>
							<p className='external-user-profile-sub-section-licence-text-light'>
								Expires -{' '}
								{utils.formatDateForUser(licence.expiryDate)}
							</p>
						</div>
					</div>
					{licence.frontReadUrl && licence.backReadUrl ? (
						<div className='external-user-profile-sub-section-image-container'>
							<div className='external-user-profile-sub-section-tab-container'>
								<div
									className={`external-user-profile-sub-section-tab ${
										selectedTabs[licence.number] === 'front'
											? 'selected'
											: ''
									}`}
									onClick={() =>
										setSelectedTabs({
											...selectedTabs,
											[licence.number]: 'front',
										})
									}
								>
									Front
								</div>
								<div
									className={`external-user-profile-sub-section-tab ${
										selectedTabs[licence.number] === 'back'
											? 'selected'
											: ''
									}`}
									onClick={() =>
										setSelectedTabs({
											...selectedTabs,
											[licence.number]: 'back',
										})
									}
								>
									Back
								</div>
							</div>
							<img
								className='external-user-profile-sub-section-licence-image'
								src={
									selectedTabs[licence.number] === 'front' &&
									licence.frontReadUrl
										? licence.frontReadUrl
										: selectedTabs[licence.number] ===
													'back' &&
											  licence.backReadUrl
											? licence.backReadUrl
											: ''
								}
								alt={`${licence.number} ${
									selectedTabs[licence.number]
								} image`}
							/>
						</div>
					) : (
						<p className='external-user-profile-sub-section-licence-no-image'>
							(No Licence Image(s) Available)
						</p>
					)}
				</section>
			</React.Fragment>
		));
	};

	return (
		<section className='view-container'>
			<ExternalUserProfileStatus
				sectionStatus={
					licencesStageData?.stageComplete ? 'verified' : ''
				}
				licenceCheckDate={
					licencesStageData?.stageComplete
						? licencesStageData.stageCompletedAt
						: ''
				}
			/>
			<p className='user-profile-section-title'>
				SIA Licences
				<img
					className='risk-flag'
					src={
						licencesStageData && licencesStageData.risks.length > 0
							? flagRed
							: flagDarkGrey
					}
					alt='flag'
				/>
			</p>
			{licences.length === 0 && (
				<div className='user-profile-section-divider-line'></div>
			)}
			<div className='user-profile-section-main'>
				{licences &&
					licences.length > 0 &&
					renderQualificationsSection()}
				{licencesStageData?.risks.length > 0 && (
					<>
						<div className='user-profile-section-divider-line'></div>
						<div className='external-user-profile-sub-section-risk-margin'>
							<ExternalUserProfileRisks
								stageData={licencesStageData}
								loggedInUser={loggedInUser}
							/>
						</div>
					</>
				)}
			</div>
		</section>
	);
};

export default ExternalUserProfileQualifications;
