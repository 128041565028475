import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import './external-invite-applicant-response.styles.scss';
import {
	ExtInviteModalFlow,
	INewCandidate,
	InviteRespSubmit,
	PassportDetails,
} from '../../../../types/interface';
import OrkaButton from '../../../general/orka-button/orka-button.component';
import ExternalPassportCard from '../external-passport-card/external-passport-card.component';
import CheckMarkPrimary from '../../../../assets/icons/check-mark-primary.svg';
import * as apiApplicant from '../../../../api/api.applicant';
import { useDispatch } from 'react-redux';
import { refetchApplicants } from '../../../../redux/Dashboard/dashboard.actions';
import { setAlert } from '../../../../redux/General/general.actions';
import { mapSubmissionStatusTab } from '../../../../utils/externalUtilsFunction';
type Props = {
	setShowModal: Dispatch<SetStateAction<boolean>>;
	setApplicantFlow: Dispatch<SetStateAction<ExtInviteModalFlow>>;
	setResponseInvite: Dispatch<SetStateAction<InviteRespSubmit>>;
	inviteRespSubmit: InviteRespSubmit;
	passportDetails: PassportDetails;
	passportFormData: INewCandidate;
};
const ExtInviteApplicantResponse: FC<Props> = ({
	setShowModal,
	setApplicantFlow,
	setResponseInvite,
	inviteRespSubmit,
	passportDetails,
	passportFormData,
}) => {
	const dispatch = useDispatch();

	const [applicantStatus, setApplicantStatus] = useState<string>('');
	const closeModal = () => {
		setShowModal(false);
	};

	const buyNow = () => {
		return apiApplicant
			.addApplicant(passportFormData)
			.then((res) => {
				if (res.status === 404) {
					throw res;
				}
				const status = mapSubmissionStatusTab(
					res.data.submissionStatusTab,
				);
				setApplicantStatus(status);
				setResponseInvite('Purchased');
				dispatch(refetchApplicants());
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message:
							err.data && err.data.message
								? err.data.message
								: err.message,
						isVisible: true,
					}),
				);
			});
	};

	const wrongName = (
		<>
			<p className='orka-p-medium'>
				<span className='semi'>
					We found this National Insurance number in our database, but
					the name, email address or phone number you entered doesn’t
					match.
				</span>
				<br></br>
				<br></br>
				Please check the NI number and try again. If this message occurs
				again, please contact the applicant to determine if they signed
				up for Deploi with different information.
			</p>
			<div className='text-right margin-top-32'>
				<OrkaButton
					buttonContent='Start Again'
					disabled={false}
					emitClicked={() => setApplicantFlow(1)}
				/>
			</div>
		</>
	);
	const wrongAll = (
		<>
			<p className='orka-p-medium'>
				<span className='semi'>
					Some of these details belong to an existing applicant in our
					database.
				</span>
				<br></br>
				<br></br>
				Please contact your applicant to ensure the details are correct
				or match the details they may have signed up to Deploi with
				previously and start again.
			</p>
			<div className='text-right margin-top-32'>
				<OrkaButton
					buttonContent='Start Again'
					disabled={false}
					emitClicked={() => setApplicantFlow(1)}
				/>
			</div>
		</>
	);

	const wrongContact = (
		<div>
			<p className='orka-p-small'>
				This applicant’s file already exists in the Deploi database and
				is available to purchase, but their contact details don’t match
				the ones we have on file.
				<br></br>
				<span className='semi'>
					Please ensure the applicant knows they may need to update
					their details with Deploi.
				</span>
			</p>
			<p className='semi margin-top-32 margin-bottom-16 text-center'>
				Is{' '}
				<span className='color-primary'>
					{passportDetails.firstName} {passportDetails.lastName}
				</span>{' '}
				the right person?
			</p>

			<ExternalPassportCard
				passportDetails={passportDetails}
				halfCard={false}
			/>

			<p className='orka-p-small margin-top-32'>
				If these details match the applicant click
				<span className='semi'> Buy Now</span>. If not click
				<span className='semi'> Start Again.</span>
			</p>
			<div className='inline-buttons'>
				<OrkaButton
					buttonContent='Start Again'
					disabled={false}
					emitClicked={() => setApplicantFlow(1)}
				/>
				<OrkaButton
					buttonContent='Buy Now'
					disabled={false}
					emitClicked={buyNow}
				/>
			</div>
		</div>
	);
	const fullMatch = (
		<div>
			<p className='semi margin-bottom-32 text-center'>
				Is{' '}
				<span className='color-primary'>
					{passportDetails.firstName} {passportDetails.lastName}
				</span>{' '}
				the right person?
			</p>

			<ExternalPassportCard
				passportDetails={passportDetails}
				halfCard={false}
			/>

			<p className='orka-p-small margin-top-32'>
				If these details match the applicant click
				<span className='semi'> Buy Now</span>. If not click
				<span className='semi'> Start Again.</span>
			</p>
			<div className='inline-buttons'>
				<OrkaButton
					buttonContent='Start Again'
					disabled={false}
					emitClicked={() => setApplicantFlow(1)}
				/>
				<OrkaButton
					buttonContent='Buy Now'
					disabled={false}
					emitClicked={buyNow}
				/>
			</div>
		</div>
	);

	const invited = (
		<div>
			<div className='text-center margin-bottom-32'>
				<img className='margin-bottom-16' src={CheckMarkPrimary} />
				<h1>Invitation Successful</h1>
			</div>

			<p className='orka-p-medium'>
				<span className='semi'>
					Great!{' '}
					<span className='color-primary'>
						{passportDetails.firstName} {passportDetails.lastName}
					</span>{' '}
					will receive an invitation to create an account and complete
					their application form immediately.
				</span>
				<br></br>
				<br></br>
				As soon as {passportDetails.firstName} accepts your invitation
				and creates an account, all of their details will become
				available and you will be notified accordingly.
				<br></br>
				<br></br>
				The charge will be reflected on your next invoice.
			</p>
			<div className='text-right margin-top-32'>
				<OrkaButton
					buttonContent='Done'
					disabled={false}
					emitClicked={closeModal}
				/>
			</div>
		</div>
	);

	const purchased = (
		<div>
			<div className='text-center margin-bottom-32'>
				<img className='margin-bottom-16' src={CheckMarkPrimary} />
				<h1>Purchased Successfully!</h1>
			</div>

			<p className='orka-p-medium'>
				<span className='semi'>
					Great!{' '}
					<span className='color-primary'>
						{passportDetails.firstName} {passportDetails.lastName}
					</span>{' '}
					has been added to your account. You can find them under “
					{applicantStatus} ”.
				</span>
				<br></br>
				<br></br>
				They have been informed that you have requested their passport.
				We will contact them to update their details, but you may wish
				to discuss the urgency of this with them too.
				<br></br>
				<br></br>
				Your charge will appear on your next invoice.
			</p>

			<div className='text-right margin-top-32'>
				<OrkaButton
					buttonContent='Done'
					disabled={false}
					emitClicked={closeModal}
				/>
			</div>
		</div>
	);

	return (
		<div>
			{inviteRespSubmit === 'FullMatch' && fullMatch}
			{inviteRespSubmit === 'WrongContact' && wrongContact}
			{inviteRespSubmit === 'WrongName' && wrongName}
			{inviteRespSubmit === 'WrongAll' && wrongAll}
			{inviteRespSubmit === 'Invited' && invited}
			{inviteRespSubmit === 'Purchased' && purchased}
		</div>
	);
};

export default ExtInviteApplicantResponse;
