import React, {
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from 'react';
import './profile-document-details.styles.scss';
import {
	IDropdownStandard,
	YotiDocumentDetails,
} from '../../../types/interface';
import * as utils from '../../../utils/utilsFunctions';
import Dropdown from '../../general/orka-dropdown/dropdown.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { IStaffMember } from '../../../types/interfaces/staff.interface';
import checkboxWhite from '../../../assets/icons/checkbox-white.svg';
import checkboxSelected from '../../../assets/icons/checkbox-tick.svg';
import * as apiId from '../../../api/api.identity';
import { ICandidateRTWDetails } from '../../../types/interfaces/identity.interface';
import { setAlert } from '../../../redux/General/general.actions';
import { useDispatch } from 'react-redux';
import AlertConfirm from '../../general/alert-confirm/alert-confirm.component';
type Props = {
	yotiDocumentDetails: YotiDocumentDetails;
	setRefetch?: Dispatch<SetStateAction<boolean>>;
	refetch?: boolean;
	isExternal?: boolean;
};

const ProfileDocumentDetails: FC<Props> = ({
	yotiDocumentDetails,
	setRefetch,
	refetch,
	isExternal,
}) => {
	const dispatch = useDispatch();
	const yotiDetails = useSelector(
		(state: RootState) => state.profile.yotiDetails,
	);
	const [staff, setStaff] = useState<IDropdownStandard[]>([]);
	const [defaultStaff, setDefaultStaff] = useState<IDropdownStandard>();
	const allStaff = useSelector((state: RootState) => state.auth.staff);
	const [checked, setChecked] = useState<boolean>(
		yotiDocumentDetails.identityVerified,
	);
	const [showAlertConfirm, setShowAlertConfirm] = useState<boolean>(false);
	const [checkedByName, setCheckedByName] = useState<string>('');
	const defaultItem: IDropdownStandard = {
		id: 0,
		value: 'Checked by',
		dbValue: 'Checked by',
		class: 'dropdown-dark',
	};
	useEffect(() => {
		defaultSelectedDropDown();
		const dropdownData = allStaff
			.filter(
				(element) =>
					element.role === 'Controller' ||
					element.role === 'Screener',
			)
			.map((staffMember: IStaffMember, index: number) => {
				return mapMember(staffMember, index);
			});

		setStaff([defaultItem, ...dropdownData]);
	}, [allStaff.length]);

	const mapMember = (staffMember: IStaffMember, index: number) => {
		return {
			id: index + 1,
			value: staffMember.name + ' ' + staffMember.surname,
			dbValue: staffMember.uuid,
			class: 'dropdown-dark',
			staffMember: staffMember,
		};
	};

	const defaultSelectedDropDown = () => {
		if (yotiDocumentDetails.evidenceCheckedBy) {
			const found = allStaff.filter(
				(element) =>
					element.uuid === yotiDocumentDetails.evidenceCheckedBy,
			);
			if (found && found.length > 0) {
				setCheckedByName(
					utils.getFullName(null, found[0].name, found[0].surname),
				);
			} else {
				setDefaultStaff(defaultItem);
			}
		} else {
			setDefaultStaff(defaultItem);
		}
	};

	const getDropdownValue = async (val: IDropdownStandard) => {
		if (val.id > 0) {
			const res: boolean = await updateYotiDetails({
				evidenceCheckedBy: val.dbValue,
			});
			if (res && setRefetch) {
				setRefetch(!refetch);
			}
		}
	};

	const markCheck = () => {
		if (checked) {
			return;
		}
		setShowAlertConfirm(true);
	};

	const handleAlert = async (val: boolean) => {
		setShowAlertConfirm(false);
		if (val) {
			const res: boolean = await updateYotiDetails({
				identityVerified: !checked,
			});
			if (res) {
				setChecked(!checked);
				yotiDocumentDetails.identityVerified = true;
			}
		}
	};

	const updateYotiDetails = (
		payload: Partial<ICandidateRTWDetails>,
	): Promise<boolean> => {
		return apiId
			.yotiUpdateDetails(yotiDetails.sessionUuId, payload)
			.then((res) => {
				if (res.status === 404) {
					dispatch(
						setAlert({
							type: 'error',
							message: 'Unable to update Passport details',
							isVisible: true,
						}),
					);
					return false;
				}
				return true;
			})
			.catch((err) => {
				dispatch(
					setAlert({
						type: 'error',
						message: err.message,
						isVisible: true,
					}),
				);
				return false;
			});
	};

	const getEvidenceCheckedByName = () => {
		const verifier = allStaff.find(
			(staff) => staff.uuid === yotiDocumentDetails.evidenceCheckedBy,
		);

		if (verifier) {
			return `${verifier.name} ${verifier.surname}`;
		}

		return 'Not yet verified';
	};

	return (
		<div className='profile-document-details'>
			<div className='margin-bottom-24'>
				<p className='orka-profile-label demi-bold'>Forename</p>
				<p className='orka-semi-p'>{yotiDocumentDetails.forename}</p>
			</div>
			<div className='margin-bottom-24'>
				<p className='orka-profile-label demi-bold'>Middle name(s)</p>
				<p className='orka-semi-p'>
					{yotiDocumentDetails.middleName
						? yotiDocumentDetails.middleName
						: '-'}
				</p>
			</div>
			<div className='margin-bottom-24'>
				<p className='orka-profile-label demi-bold'>Surname</p>
				<p className='orka-semi-p'>{yotiDocumentDetails.surname}</p>
			</div>
			<div className='margin-bottom-24'>
				<p className='orka-profile-label demi-bold'>Date of birth</p>
				<p className='orka-semi-p'>
					{yotiDocumentDetails.dateOfBirth
						? utils.formatDateForUser(
								yotiDocumentDetails.dateOfBirth,
							)
						: '-/-/-'}
				</p>
			</div>
			<div className='margin-bottom-24'>
				<p className='orka-profile-label demi-bold'>Nationality</p>
				<p className='orka-semi-p'>
					{yotiDocumentDetails.nationality
						? yotiDocumentDetails.nationality
						: '-'}
				</p>
			</div>
			<div className='margin-bottom-24'>
				<p className='orka-profile-label demi-bold'>
					Expiry date of document
				</p>
				<p className='orka-semi-p'>
					{yotiDocumentDetails.expiryDate
						? utils.formatDateForUser(
								yotiDocumentDetails.expiryDate,
							)
						: '-/-/-'}
				</p>
			</div>

			<div className='margin-bottom-24'>
				<p className='orka-profile-label demi-bold'>
					Identity Verified
				</p>
				{isExternal ? (
					<p className='orka-semi-p'>
						{yotiDocumentDetails.identityVerified
							? 'Yes'
							: 'Not yet verified'}
					</p>
				) : (
					<div className='checkbox-area'>
						<img
							className={checked ? 'checkbox-disabled' : ''}
							onClick={() => markCheck()}
							src={checked ? checkboxSelected : checkboxWhite}
							alt='checkbox'
						/>
						<p className='orka-semi-p margin-left-8'>Yes</p>
					</div>
				)}
			</div>
			<div className='margin-bottom-24'>
				<p className='orka-profile-label demi-bold'>
					Evidence checked by
				</p>
				{isExternal ? (
					<p className='orka-semi-p'>
						{yotiDocumentDetails.evidenceCheckedBy
							? getEvidenceCheckedByName()
							: 'Not yet verified'}
					</p>
				) : checkedByName ? (
					<p className='orka-semi-p'>{checkedByName}</p>
				) : (
					<div className='margin-top-8'>
						{defaultStaff && staff && (
							<Dropdown
								type='staff'
								dropdownData={staff}
								defaultData={defaultStaff}
								getDropdownVal={getDropdownValue}
								width='180'
								dropDownWidth='290'
								mobileResponsive={false}
								position='bottom-left'
								height={24}
							/>
						)}
					</div>
				)}
			</div>
			<div className='margin-bottom-24'>
				<p className='orka-profile-label demi-bold'>Date of check</p>
				<p className='orka-semi-p'>
					{yotiDocumentDetails.dateOfCheck
						? utils.formatDateForUser(
								yotiDocumentDetails.dateOfCheck,
							)
						: '-/-/-'}
				</p>
			</div>
			<div className='margin-bottom-24'>
				<p className='orka-profile-label demi-bold'>
					GPG45 Profile (level of confidence)
				</p>
				<p className='orka-semi-p'>
					{utils.capitalizeFirstLetter(
						yotiDocumentDetails.levelOfConfidence,
					)}
				</p>
			</div>

			{showAlertConfirm && (
				<AlertConfirm
					message={`Are you sure you want to check Identity Verified for this user?`}
					getVal={handleAlert}
					confirm='Yes'
					reject='Cancel'
				/>
			)}
		</div>
	);
};

export default ProfileDocumentDetails;
