import React, { useEffect, useState, FC } from 'react';
import './certificate-header.styles.scss';
import logo from '../../../assets/logos/deploi-logo.svg';
import { useSelector } from 'react-redux';
import provisionallyLogo from '../../../assets/icons/certificate/provisionally.svg';
import incompleteLogo from '../../../assets/icons/certificate/incomplete-header.svg';
import fullyLogo from '../../../assets/icons/certificate/fully.svg';
import { IApplicant } from '../../../types/interface';
import * as utils from '../../../utils/utilsFunctions';

type RootState = {
	profile: {
		applicant: IApplicant;
	};
};

const CertificateHeader: FC = () => {
	const clearance = useSelector(
		(state: RootState) => state.profile.applicant.clearance,
	);
	const [icon, setIcon] = useState('');
	const [clearanceText, setClearanceText] = useState('');
	const [clearanceDate, setClearanceDate] = useState<any>('');

	useEffect(() => {
		switch (clearance?.clearanceStatus) {
			case 'fully_compliant':
				setIcon(fullyLogo);
				setClearanceText(
					'This certifies that full screening (in line with the BS7858:2019 code of practise) has been completed for:',
				);
				setClearanceDate(
					`Cleared: Cassie Watson - ${utils.formatDateForUser(
						clearance.fullyClearedDate,
					)}`,
				);
				break;
			case 'partially_compliant':
				setIcon(provisionallyLogo);
				setClearanceText(
					'This certifies that limited screening (in line with the BS7858:2019 code of practise) has been completed for:',
				);
				setClearanceDate(
					`Cleared: Cassie Watson - ${utils.formatDateForUser(
						clearance.provisionallyClearedDate,
					)}`,
				);
				break;
			default:
				setIcon(incompleteLogo);
				setClearanceText('This applicant has not been cleared');
				break;
		}
	}, [clearance?.clearanceStatus]);

	return (
		<div className='certificate-container'>
			<div className='certificate-header-new'>
				<img className='deploi-img' src={logo} alt='logo' />
				<div className='certificate-header-right'>
					<img
						className='screening-icon'
						src={icon}
						alt='clearance status logo'
					/>
					<p className='certificate-title-text'>
						BS7858 Clearance Summary
					</p>
				</div>
			</div>
			<p className='certificate-header-text'>{clearanceDate}</p>
			<p className='certificate-header-text'>{clearanceText}</p>
		</div>
	);
};

export default CertificateHeader;
