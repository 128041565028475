import React, { FC } from 'react';
import './alert-confirm.component.scss';

type Props = {
	title?: string;
	message: string;
	getVal: (val: boolean) => void;
	confirm: string;
	reject: string;
};

const AlertConfirm: FC<Props> = ({
	title,
	message,
	getVal,
	confirm,
	reject,
}) => {
	return (
		<div className='alert-confirm'>
			<div className='alert-confirm-message'>
				<h2>{title ? title : 'Are you sure?'}</h2>
				<p>{message}</p>
			</div>
			<div className='alert-confirm-buttons'>
				<button
					className='orka-btn reject'
					onClick={() => getVal(false)}
				>
					{reject}
				</button>
				<button
					className='orka-btn confirm'
					onClick={() => getVal(true)}
				>
					{confirm}
				</button>
			</div>
		</div>
	);
};

export default AlertConfirm;
