import React, {
	useCallback,
	useEffect,
	FC,
	Dispatch,
	SetStateAction,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { resetSelectedFiles } from '../../../redux/Profile/profile.actions';
import './profile-file-bucket.styles.scss';
import { RootState } from '../../../types/root-state';
import { IFileItem } from '../../../types/interfaces/document.interface';

type Props = {
	emitFiles: (acceptedFiles: IFileItem[], side: string) => void;
	side: string;
	allowMultiple: boolean;
	openDialog: boolean;
	setOpenDialog: Dispatch<SetStateAction<boolean>>;
	openFooter: boolean;
	fileNumber: number;
	isWork?: boolean;
};

const ProfileFileBucket: FC<Props> = ({
	emitFiles,
	side,
	allowMultiple,
	openDialog,
	setOpenDialog,
	openFooter,
	fileNumber,
	isWork = false,
}) => {
	const dispatch = useDispatch();
	const isMobile = useSelector((state: RootState) => state.general.isMobile);

	useEffect(() => {
		dispatch(resetSelectedFiles());
	}, [dispatch]);

	const onDrop = useCallback((acceptedFiles: any) => {
		emitFiles(acceptedFiles, side);
	}, []);

	const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
		onDrop,
	});

	useEffect(() => {
		if (openDialog && !openFooter) {
			open();
			setOpenDialog(false);
		}
	}, [openDialog, open, setOpenDialog, openFooter]);

	return (
		<div className='file-upload-container'>
			{(fileNumber > 0 || isWork === false) && (
				<div className='file-upload-spacer'></div>
			)}
			<div
				{...getRootProps()}
				className={
					isDragActive
						? 'orka-check-file-bucket active'
						: 'orka-check-file-bucket'
				}
			>
				<input {...getInputProps()} multiple={allowMultiple} />
				<div className='files-empty'>
					{isMobile ? (
						<p className='grey-text'>Click to upload files</p>
					) : (
						<p className='grey-text'>
							{/* eslint-disable-next-line react/no-unescaped-entities */}
							Drag 'n' drop, or click to&nbsp;
							<span className='underline'>Upload files</span>
						</p>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProfileFileBucket;
