import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import CertificateInfoItem from '../certificate-info-item/certificate-info-item.component';
import '../certificate-info/certificate-info.styles.scss';
import './certificate-clearance.styles.scss';
import * as utils from '../../../utils/utilsFunctions';
import { IApplicant } from '../../../types/interface';

type RootState = {
	profile: {
		applicant: IApplicant;
	};
};

const CertificateClearance: FC = () => {
	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);
	const clearance = useSelector(
		(state: RootState) => state.profile.applicant.clearance,
	);
	return (
		<div className='certificate-info-container'>
			<div className='cert-section-title'>
				<p>Clearance Information</p>
			</div>
			<div className='certificate-info-container-main'>
				<div className='certificate-info-container-side'>
					<CertificateInfoItem
						label='Clearance Type'
						value={'Limited Screening'}
					/>
					<CertificateInfoItem
						label='Clearance Date'
						value={
							clearance?.provisionallyClearedDate
								? utils.formatDateForUser(
										clearance.provisionallyClearedDate,
									)
								: 'No limited screening'
						}
					/>
					<CertificateInfoItem
						label='Cleared by'
						value={
							clearance?.provisionallyClearedBy
								? clearance.provisionallyClearedBy
								: 'No limited screening'
						}
					/>
				</div>
				<div className='certificate-info-container-side'>
					<CertificateInfoItem
						label='Clearance Type'
						value={'Full Screening'}
					/>
					<CertificateInfoItem
						label='Clearance Date'
						value={
							clearance?.fullyClearedDate
								? utils.formatDateForUser(
										clearance.fullyClearedDate,
									)
								: 'Not fully cleared'
						}
					/>
					<CertificateInfoItem
						label='Cleared by'
						value={
							clearance?.fullyClearedBy
								? clearance.fullyClearedBy
								: 'Not fully cleared'
						}
					/>
				</div>
			</div>
			<div className='file-assigned-to'>
				<CertificateInfoItem
					label='File assigned to'
					value={
						applicant.clearedAssignedTo
							? utils.capitalizeFirstLetterOfEach(
									applicant.clearedAssignedTo,
								)
							: 'Tom Kiggins'
					}
				/>
			</div>
		</div>
	);
};

export default CertificateClearance;
