import './external-user-profile-sections.styles.scss';

import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/root-state';
import { formatDateToText } from '../../../utils/externalUtilsFunction';
import {
	IFileItem,
	YotiDocs,
} from '../../../types/interfaces/document.interface';
import { YotiDocumentDetails } from '../../../types/interface';

import * as utils from '../../../utils/utilsFunctions';
import ExternalUserProfileStatus from './external-user-profile-status.component';
import LivenessCheck from '../../profile/liveness-check/liveness-check.component';
import RtwImageDoc from '../../profile/rtw-image-doc/rtw-image-doc';
import ProfileDocumentDetails from '../../profile/profile-document-details/profile-document-details.component';

import cheveronDown from '../../../assets/icons/chevron-down.svg';
import livenessIcon from '../../../assets/icons/external-portal/user-profile/liveness.svg';
import identityIcon from '../../../assets/icons/external-portal/user-profile/identity.svg';
import greenTick from '../../../assets/icons/green-tick.svg';
import downloadFileIcon from '../../../assets/icons/external-portal/user-profile/download-file.svg';

type Props = {
	rtwManualDocArray: IFileItem[];
	yotiFaceToFaceArray: YotiDocs[];
	yotiRtwArray: YotiDocs[];
	faceToFaceArray: IFileItem[];
	yotiDocumentDetails?: YotiDocumentDetails;
};

const ExternalUserProfileIdentity: FC<Props> = ({
	rtwManualDocArray,
	yotiFaceToFaceArray,
	yotiRtwArray,
	faceToFaceArray,
	yotiDocumentDetails,
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const collapsibleSectionStyle = { display: isOpen ? 'block' : 'none' };

	const applicant = useSelector(
		(state: RootState) => state.profile.applicant,
	);

	const yotiDetails = useSelector(
		(state: RootState) => state.profile.yotiDetails,
	);

	const stageData = useSelector(
		(state: RootState) => state.profile.stageData,
	);

	const { ambassadorCheckStatus } = useSelector(
		(state: RootState) => state.profile,
	);

	const visibleFiles = faceToFaceArray.filter(
		(file: IFileItem) => !file.deletedAt && file.status !== 'rejected',
	);

	const mostRecentImg = () => {
		return rtwManualDocArray.slice(-1)[0];
	};

	const livenessCheck = () => {
		if (!yotiDetails || yotiFaceToFaceArray.length === 0) {
			return;
		}
		return (
			<LivenessCheck
				fileArray={yotiFaceToFaceArray}
				showContainerClass={false}
			/>
		);
	};

	const renderFaceToFaceFileSection = () => {
		return (
			<>
				{faceToFaceArray && faceToFaceArray.length > 0 ? (
					<div className='external-user-profile-sub-section-body'>
						<div className='external-user-profile-sub-section-file-container'>
							<p className='external-user-profile-sub-section-file-title'>
								Files
							</p>
							{visibleFiles.map(
								(file: IFileItem, index: number) => (
									<div
										key={index}
										className='external-user-profile-sub-section-file-content'
									>
										<a
											href={file.readUrl}
											target='_blank'
											rel='noreferrer'
											className='external-user-profile-sub-section-file-link'
										>
											<img
												className='external-user-profile-sub-section-file-icon'
												src={downloadFileIcon}
												alt='file-icon'
											/>
											<span className='external-user-profile-sub-section-file-number'>
												File{' '}
												{visibleFiles.indexOf(file) + 1}
											</span>
										</a>
									</div>
								),
							)}
						</div>
					</div>
				) : (
					<div className='external-user-profile-sub-section-file-container'>
						<p className='external-user-profile-sub-section-file-title'>
							Files
						</p>
						<p className='external-user-profile-sub-section-file-text'>
							No Files Available
						</p>
					</div>
				)}
			</>
		);
	};

	const renderRTWPassportSection = () => {
		return (
			<section className='external-user-profile-sub-section-container'>
				<div className='external-user-profile-sub-section-title-container remove-pointer'>
					<img
						className='external-user-profile-sub-section-icon'
						src={identityIcon}
						alt='identity-icon'
					></img>
					<p className='external-user-profile-sub-section-title'>
						Right to Work Check - Passport
					</p>
				</div>
				{yotiDocumentDetails ? (
					<div className='external-user-profile-sub-section-identity-container'>
						<ProfileDocumentDetails
							yotiDocumentDetails={yotiDocumentDetails}
							isExternal={true}
						/>
					</div>
				) : null}
				<div className='external-user-profile-sub-section-divider-line'></div>
			</section>
		);
	};

	const renderLivenessSection = () => {
		return (
			<section className='external-user-profile-sub-section-container'>
				<div className='external-user-profile-sub-section-title-container remove-pointer'>
					<img
						className='external-user-profile-sub-section-icon'
						src={livenessIcon}
						alt='liveness-icon'
						style={{ width: 15 }}
					></img>
					<p className='external-user-profile-sub-section-title'>
						Liveness Check
					</p>
				</div>
				<div className='external-user-profile-sub-section-identity-container'>
					{livenessCheck()}
				</div>
				<div className='external-user-profile-sub-section-divider-line'></div>
			</section>
		);
	};

	const renderRtwSection = () => {
		return (
			<section className='external-user-profile-sub-section-container'>
				<div className='external-user-profile-sub-section-title-container remove-pointer'>
					<img
						className='external-user-profile-sub-section-icon'
						src={identityIcon}
						alt='identity-icon'
					></img>
					<p className='external-user-profile-sub-section-title'>
						Identity Verification
					</p>
				</div>
				{yotiRtwArray && yotiRtwArray.length > 0 ? (
					<>
						{yotiRtwArray && yotiRtwArray.length > 0 && (
							<div className='external-user-profile-sub-section-identity-container'>
								<div className='external-rtw-manual-container'>
									<RtwImageDoc
										imgSrc={yotiRtwArray[0].readUrl}
									/>
								</div>
							</div>
						)}
					</>
				) : null}
				{rtwManualDocArray && rtwManualDocArray.length > 0 ? (
					<div className='external-user-profile-sub-section-identity-container'>
						<div className='external-rtw-manual-container'>
							<div className='confidence-level margin-bottom-24'>
								<img src={greenTick} alt='Check' />
								Medium level of confidence
							</div>
							<>
								<RtwImageDoc imgSrc={mostRecentImg().readUrl} />
							</>
						</div>
					</div>
				) : null}
				<div className='external-user-profile-sub-section-divider-line'></div>
			</section>
		);
	};

	const renderIdentitySection = () => {
		return (
			<section className='external-user-profile-sub-section-container'>
				<div className='external-user-profile-sub-section-title-container remove-pointer'>
					<img
						className='external-user-profile-sub-section-icon'
						src={identityIcon}
						alt='identity-icon'
					></img>
					<p className='external-user-profile-sub-section-title'>
						Identity Verification
					</p>
				</div>
				<div className='external-user-profile-sub-section-container'>
					<div className='external-user-profile-sub-section-identity-subtitle-container'>
						<p className='external-user-profile-sub-section-identity-subtitle'>
							Ambassador Name:
						</p>
						<div className='external-user-profile-sub-section-identity-body'>
							{applicant.f2fCompletedBy
								? applicant.f2fCompletedBy
								: 'N/A'}
						</div>
					</div>
					<div className='external-user-profile-sub-section-identity-subtitle-container'>
						<p className='external-user-profile-sub-section-identity-subtitle'>
							Date Checked:
						</p>
						<div className='external-user-profile-sub-section-identity-body'>
							{applicant.f2fCompletedDate
								? utils.formatDateForUser(
										applicant.f2fCompletedDate,
									)
								: 'N/A'}
						</div>
					</div>
					{renderFaceToFaceFileSection()}
				</div>
				<div className='external-user-profile-sub-section-divider-line'></div>
			</section>
		);
	};

	const renderNISection = () => {
		return (
			<section className='external-user-profile-sub-section-container'>
				<div className='external-user-profile-sub-section-title-container remove-pointer'>
					<img
						className='external-user-profile-sub-section-icon'
						src={identityIcon}
						alt='identity-icon'
						style={{ width: 16 }}
					></img>
					<p className='external-user-profile-sub-section-title'>
						NI Number
					</p>
				</div>
				<div className='external-user-profile-sub-section-subtitle-container'>
					<p className='external-user-profile-sub-section-subtitle'>
						{applicant.nationalInsuranceNumber.toUpperCase()}
					</p>
				</div>
				<div className='external-user-profile-sub-section-divider-line'></div>
			</section>
		);
	};

	const getSectionStatus = () => {
		if (stageData[0]?.stageComplete && stageData[7]?.stageComplete) {
			return 'verified';
		} else if (stageData[0]?.stageComplete || stageData[7]?.stageComplete) {
			return 'partially_verified';
		} else {
			return '';
		}
	};

	return (
		<section className='view-container '>
			<ExternalUserProfileStatus sectionStatus={getSectionStatus()} />
			<p
				className='user-profile-section-title identity-container'
				onClick={() => setIsOpen(!isOpen)}
			>
				Right to Work and ID Verification
				<img
					className='chevron'
					src={cheveronDown}
					alt='arrow'
					style={{ transform: isOpen ? 'scaleY(-1)' : 'none' }}
				></img>
			</p>
			<div className='user-profile-section-divider-line'></div>
			<div
				className='user-profile-section-main'
				style={collapsibleSectionStyle}
			>
				{yotiDocumentDetails && renderRTWPassportSection()}
				{livenessCheck() && renderLivenessSection()}
				{((yotiRtwArray && yotiRtwArray.length > 0) ||
					(rtwManualDocArray && rtwManualDocArray.length > 0)) &&
					renderRtwSection()}
				{!yotiDetails &&
					ambassadorCheckStatus &&
					ambassadorCheckStatus.status !== 404 &&
					ambassadorCheckStatus.status !== 'started' &&
					renderIdentitySection()}
				{applicant.nationalInsuranceNumber && renderNISection()}
			</div>
			<div className='last-verified'>
				{stageData[0]?.stageComplete || stageData[7]?.stageComplete
					? 'Last Verified on ' +
						formatDateToText(
							stageData[0].stageCompletedAt ||
								stageData[7].stageCompletedAt,
						)
					: ''}
			</div>
		</section>
	);
};

export default ExternalUserProfileIdentity;
